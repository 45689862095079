jQuery('#main-navigation-toggle').click(function() {
  jQuery(this).toggleClass('open');
  jQuery('#mobile-navigation').toggleClass('open');
  jQuery('body').toggleClass('no-scroll');
});

jQuery('.menu-item-has-children').click(function() {
  let isThisOpen = jQuery(this).hasClass('open');
  console.log('isThisOpen: ' + isThisOpen);
  jQuery('.menu-item-has-children.open').each(function() {
    jQuery(this).removeClass('open');
  });
  if ( !isThisOpen ) {
    jQuery(this).addClass('open');
  }
});

jQuery('.menu-item-has-children').each(function(){
  jQuery(this).children('a:first').replaceWith(function() {
    return '<span class="wannabea" tabindex="0">' + jQuery(this).text() + '</span>'
  });
  
});

jQuery('.menu-item-has-children').keyup(function(event) {
  if (event.keyCode === 13) { // 13 => Enter
    jQuery(this).click();
  }
});

jQuery(document).click(function(){
  jQuery('.menu-item-has-children.open').removeClass('open');
});
 
jQuery('.menu-item-has-children').click(function(e){
  e.stopPropagation();
});

jQuery('.menu-item-has-children').mouseleave(function(){
  //jQuery(this).removeClass('open');
});